import React from "react";
import Layout from "../layout/Layout";
import video from "../assets/videos/Buying on the Xchange Marketplace (1).mp4";
import { Box, Button, Grid, Typography, Slider } from "@mui/material";
import bgimg from "../assets/images/newbg.png";

function BuyerhubPage() {
  return (
    <Layout pageName="Buyer Hub">
      <div className="main">
        {/* <div className="jumbotron banner-buy">
          <div className="container">
            <h1 className="title-top">Buyer Hub</h1>
            <h2>THE XCHANGE MARKETPLACE</h2>
            <h4>A Secure Online Portal for Buyers</h4>
            <div>
              <a
                className="btn btn-primary btn-md"
                href=""
                role="button"
              >
                Click Here to Join Us – Start Shopping
              </a>
            </div>
          </div>
        </div> */}
        <Grid container sx={{ my: 5 }}>
          <Grid item xs={12}>
            <Box
              sx={{
                background: `url(${bgimg})`,
                backgroundSize: { xs: "100% 100%" },
                minHeight: "50vh",
                py: 7,
                px: { xs: 2, md: 6 },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  fontSize: { xs: "1.2rem", md: "3rem" },
                  mt: { xs: 3, md: 6 },
                }}
              >
                THE XCHANGE MARKETPLACE
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  pt: 3,
                  fontSize: { xs: "1rem", md: "2.5rem" },
                }}
              >
                A Secure Online Portal for Buyers
              </Typography>
              <Box>
                <Button
                  variant="contained"
                  size="large"
                  color="warning"
                  href="/xchangeShop"
                  sx={{
                    mt: 3,
                    px: 3,
                    borderRadius: "15px",
                    fontWeight: "bold",
                    fontSize: { xs: ".5rem", md: "1.5rem" },
                    backgroundColor: "rgb(248, 158, 35)",
                    "&:hover": {
                      backgroundColor: "rgb(248, 158, 35)",
                      color: "white",
                    },
                  }}
                >
                  Start Shopping
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* end jumbotron*/}
        <p style={{ fontSize: "1em" }}>
          Developed by Scientists and Engineers, XCHANGEMarketplace.com is an
          independent subscription-based marketplace website for buying and
          selling new, used, ex-demonstration and refurbished testing equipment
          and supplies. Our aim is to offer a resale platform which specifically
          targets the underserved industrial and academic sectors.
        </p>
        <div className="getStarted-sec">
          <div className="container">
            <h2 className="text-center">
              Here’s what you need to know to get started
            </h2>
            <ul className="d-flex ul-none">
              <li style={{ display: "flex" }}>
                <div className="getStarted-icon">
                  <img
                    src="assets/images/icon-buy-1.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="getStarted-text">
                  <h3>Sign up</h3>
                  <p>Complete the EZ Form</p>
                  <a href="http://shop.thexchangemarketplace.com/sign-up-buyer">
                    Learn more <i className="fas fa-chevron-right" />
                  </a>
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div className="getStarted-icon">
                  <img
                    src="assets/images/icon-buy-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="getStarted-text">
                  <h3>Shop for Equipment</h3>
                  <p>Browse our Showcase</p>
                  <a href="http://shop.thexchangemarketplace.com/">
                    Learn more <i className="fas fa-chevron-right" />{" "}
                  </a>
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div className="getStarted-icon">
                  <img
                    src="assets/images/icon-buy-3.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="getStarted-text">
                  <h3>Buy Equipment</h3>
                  <p>Select the Product</p>
                  <a href="http://shop.thexchangemarketplace.com/buy-the-equipment-buyer">
                    Learn more <i className="fas fa-chevron-right" />
                  </a>
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div className="getStarted-icon">
                  <img
                    src="assets/images/icon-buy-4.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="getStarted-text">
                  <h3>Received Equipment</h3>
                  <p>Arrange Delivery</p>
                  <a href="https://metalanalysisgroup.com/received-equipment-buyer">
                    Learn more <i className="fas fa-chevron-right" />
                  </a>
                </div>
              </li>
              <li style={{ display: "flex" }}>
                <div className="getStarted-icon">
                  <img
                    src="assets/images/icon-buy-5.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="getStarted-text">
                  <h3>Use Equipment</h3>
                  <p>Start Testing</p>
                  <a href="https://metalanalysisgroup.com/use-equipment-buyer">
                    Learn more <i className="fas fa-chevron-right" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {/* end get started*/}
        <div className="welcome_section">
          <div className="container" style={{ paddingBottom: "0.5em" }}>
            <div className="row">
              <div className="col-md-6">
                <div className="video-wrapper">
                  <video
                    width="100%"
                    height={360}
                    src={video}
                    title="How Buying on the XCHANGE MARKETPLACE WORKS"
                    frameBorder={0}
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    autoPlay={false}
                    controls
                  />
                </div>
                <p style={{ fontSize: "1em",marginTop:"20px" }}>
                  {" "}
                  Our aim is to offer a resale platform which specifically
                  targets the underserved industrial and academic sectors.
                </p>
                
              </div>
              <div className="col-md-6">
                <div className="welcome-block">
                  <div className="heading-style1 buy_page">
                    <div className="d-flex">
                      <div>
                        <img src="assets/images/icon-17.png" alt="" />
                      </div>
                      <div>
                        <h3 className="mx-3" style={{ color: "#fb9d1e" }}>
                          <span>Watch our video</span>
                        </h3>
                      </div>
                    </div>
                    <h2 className="buy-sell-title">
                      How Buying on the XCHANGE MARKETPLACE WORKS{" "}
                    </h2>
                  </div>
                  <p style={{ fontSize: "1em" }}>
                  Enjoy a seamless shopping experience with The XCHANGE MARKETPLACE, Save money and discover unique products from a wide array of businesses. Explore a diverse marketplace where every visit is an opportunity to purchase something special. Find and connect with companies offering exactly what you need. Take advantage of this innovative e-commerce platform to find great deals and explore new products.

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end welcome section */}
        <div className="gettheapp_section" id="buying-xchange-values">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <h2>
                  Buying on <span>the Xchange</span>
                </h2>
                <h4>
                  Shop Online at your Leisure and Convenience Save Money with
                  Options for Used and PreOwned Testing Equipment
                </h4>
                <div className="xchange-value">
                  <ul>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/buy-product-securely-buyer">
                        Buy Products Securely{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/purchase-with-ease-buyer">
                        Purchase with Ease{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/avoid-the-hassle-buyer">
                        Avoid the Hassle of Normal Sales Process{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/bypass-sales-persons-buyer">
                        Bypass Salespersons{" "}
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/experience-product-videos-buyer">
                        {" "}
                        Experience Product Videos{" "}
                      </a>
                    </li>
                    <li>
                      <a href="http://shop.thexchangemarketplace.com/request-virtual-demonstrations-buyer">
                        Request Virtual Demonstrations
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/get-support-from-the-xchange-team-buyer">
                        Get Support from the Xchange Team
                      </a>
                    </li>
                    <li>
                      <a href="http://shop.thexchangemarketplace.com/communicate-via-private-message-buyer">
                        Communicate via Private Message
                      </a>
                    </li>
                    <li>
                      {" "}
                      <a href="http://shop.thexchangemarketplace.com/rent-and-lease-to-own-buyer">
                        {" "}
                        Rent & Lease to Own
                      </a>
                    </li>
                    <li>
                      <a href="http://shop.thexchangemarketplace.com/access-network-or-service-providers-seller">
                        Access Network of Service Providers
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5 mt-5 mob-view ">
                <img
                  src="assets/images/cart-img.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        {/* end buy_sell_section */}
        <div className="whoShouldBuy">
          <div className="container">
            <div className="whoShouldBuyWrap">
              <div className="row">
                <div className="col-md-4 bg-whoShouldBuy">
                  <h2>
                    Who should Buy <span>On the Xchange</span>
                  </h2>
                  <a
                    className="btn btn-primary btn-sm btn-style1"
                    href="http://shop.thexchangemarketplace.com/who-should-buy-buyer"
                    role="button"
                  >
                    Learn More
                  </a>
                </div>
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="wsb-list">
                        <div className="wsb-list-img">
                          <img
                            src="assets/images/icon-10.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h3>Testing Professionals</h3>
                        <a href="http://shop.thexchangemarketplace.com/testing-professionals-buyer">
                          Learn more{" "}
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wsb-list">
                        <div className="wsb-list-img">
                          <img
                            src="assets/images/icon-11.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h3>Industrial & Scientific Companies</h3>
                        <a href="http://shop.thexchangemarketplace.com/industrial-scientific-compainies-buyer">
                          Learn more{" "}
                        </a>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="wsb-list">
                        <div className="wsb-list-img">
                          <img
                            src="assets/images/icon-12.jpg"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                        <h3>Equipment Manufacturers & Resellers</h3>
                        <a href="http://shop.thexchangemarketplace.com/equipment-manufacturers-and-resllers-buyer">
                          Learn more{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end whoShouldBuy */}
        <div className="whybuy">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h2>
                  Why Buy <span>On the Xchange</span>{" "}
                </h2>
                <a
                  className="btn btn-primary btn-sm btn-style1"
                  href="http://shop.thexchangemarketplace.com/why-buy-buyer"
                  role="button"
                >
                  Learn More
                </a>
              </div>
              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-3 col-6">
                    <div className="wb-list text-center">
                      <div className="wb-list-img">
                        <img
                          src="assets/images/icon-18.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h3>Easy to Shop Online</h3>
                      <a href="http://shop.thexchangemarketplace.com/easy-to-">
                        Learn more{" "}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="wb-list text-center">
                      <div className="wb-list-img">
                        <img
                          src="assets/images/icon-19.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h3>Variety of Products</h3>
                      <a href="http://shop.thexchangemarketplace.com/variety-of-products-buyer">
                        Learn more{" "}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="wb-list text-center">
                      <div className="wb-list-img">
                        <img
                          src="assets/images/icon-20.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h3>Perfectly Match Buyers &amp; Sellers</h3>
                      <a href="http://shop.thexchangemarketplace.com/perfectly-match-buyers-sellers-buyer">
                        Learn more{" "}
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="wb-list text-center">
                      <div className="wb-list-img">
                        <img
                          src="assets/images/icon-21.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <h3>Cost Savings</h3>
                      <a href="http://shop.thexchangemarketplace.com/cost-savings-buyer">
                        Learn more{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end whybuy */}
        <div className="howToBuy">
          <div className="container">
            <div className="row">
              <div className="col-md-3">
                <h2>
                  How to Buy <span>on the Xchange</span>
                </h2>
                <a
                  className="btn btn-primary btn-sm btn-style1"
                  href="http://shop.thexchangemarketplace.com/how-to-buy-buyer"
                  role="button"
                >
                  Learn More
                </a>
              </div>
              <div className="col-md-9 howtobuyright">
                <div className="row">
                  <div className="col-md-3  col-6">
                    <div className="htb-prog-img">
                      <img
                        src="assets/images/icon-13.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="htb-prog-cont htp-step1">
                      <a
                        href="http://shop.thexchangemarketplace.com/create-account-buyer"
                        style={{ cursor: "pointer" }}
                      >
                        <h4>STEP 1</h4>
                        <h5 style={{ color: "black" }}>Create an Account</h5>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="htb-prog-img">
                      <img
                        src="assets/images/icon-14.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="htb-prog-cont htp-step2">
                      <a href="http://shop.thexchangemarketplace.com/">
                        <h4>STEP 2</h4>
                        <h5 style={{ color: "black" }}>Shop THE XCHANGE</h5>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="htb-prog-img">
                      <img
                        src="assets/images/icon-15.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="htb-prog-cont htp-step3">
                      <a href="http://shop.thexchangemarketplace.com/find-product-buyer">
                        <h4>STEP 3</h4>
                        <h5 style={{ color: "black" }}>Find Your Product</h5>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-3  col-6">
                    <div className="htb-prog-img">
                      <img
                        src="assets/images/icon-16.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="htb-prog-cont htp-step4">
                      <a href="http://shop.thexchangemarketplace.com/connect-with-seller-buyer">
                        <h4>STEP 4</h4>
                        <h5 style={{ color: "black" }}>Connect with Seller</h5>
                      </a>
                    </div>
                  </div>
                </div>
                {/* end row */}
                <div className="row">
                  <div className="col-md-12 text-center img-progress">
                    <img
                      src="assets/images/progress-bar.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                {/* end row */}
              </div>
            </div>
          </div>
        </div>
        {/* end How to Buy */}
        {/* <div className="gettheapp_section style2">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2>Get the App.</h2>
                <h3>Stay Connected . Shop . Get Matched . Buy</h3>
                <h4>THE XCHANGE MARKETPLACE</h4>
                <div className="app-widget">
                  <h4>App Now Available</h4>
                  <ul>
                    <li>
                      <a href="#">
                        <img src="assets/images/app-store.png" alt="" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img src="assets/images/app-store-google.png" alt="" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-6 text-center mob-view">
                <img
                  src="assets/images/bg-3.png"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div> */}
        {/* end buy_sell_section */}
        <div className="recent_reviews_section">
          <div className="container">
            <h2 className="text-center">
              <span>Recent reviews</span> from buyers and sellers
            </h2>
            {/*Carousel Wrapper*/}
            <div
              id="multi-item-example"
              className="carousel slide carousel-multi-item"
              data-ride="carousel"
            >
              {/*Controls*/}
              <div className="controls-top">
                <a
                  className="btn-floating btn-left"
                  href="#multi-item-example"
                  data-slide="prev"
                >
                  <i className="fas fa-chevron-left" />
                </a>
                <a
                  className="btn-floating btn-right"
                  href="#multi-item-example"
                  data-slide="next"
                >
                  <i className="fas fa-chevron-right" />
                </a>
              </div>
              {/*/.Controls*/}
              {/*Slides*/}
              <div className="carousel-inner" role="listbox">
                {/*First slide*/}
                <div className="carousel-item active">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Fantastic Experience !</h4>
                          <p className="card-text">
                            I have started using the XCHANGE MARKETPLACE and it
                            has consistently exceeded my expectations. The user
                            interface is intuitive, making transactions smooth
                            and hassle-free. The customer support team is
                            responsive and helpful. I highly recommend this
                            platform for anyone looking for a reliable and
                            user-friendly exchange marketplace.
                          </p>
                          <div className="custmoer_name">
                            Cassandra Marie Thornton
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title"> Great Platform</h4>
                          <p className="card-text">
                            Overall, my experience with this exchange
                            marketplace has been positive. The variety of
                            options available for trading is impressive, and the
                            platform is easy to navigate. However, there have
                            been a few minor technical glitches that affected my
                            experience. Nevertheless, the customer support team
                            addressed my concerns promptly, earning them four
                            stars.
                          </p>
                          <div className="custmoer_name">
                            Elijah Jackson Bennett
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Top-Notch Security</h4>
                          <p className="card-text">
                            Security is my top priority when it comes to
                            exchange marketplaces, and this platform doesn't
                            disappoint. The two-factor authentication and
                            encryption measures in place provide a strong sense
                            of confidence in the safety of my transactions. The
                            seamless integration of security features with user
                            experience earns this platform a well-deserved five
                            stars.
                          </p>
                          <div className="custmoer_name">
                            Olivia Grace Henderson
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*/.First slide*/}
                {/*Second slide*/}
                <div className="carousel-item">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">
                            Excellent Customer Service
                          </h4>
                          <p className="card-text">
                            What sets the XCHANGE MARKETPLACE apart is its
                            exceptional customer service. The support team is
                            knowledgeable, responsive, and genuinely interested
                            in resolving any issues that users may encounter.
                            Their dedication to customer satisfaction deserves a
                            five-star rating.
                          </p>
                          <div className="custmoer_name">
                            Madeline Rose Mitchell
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">
                            Reliable, Trustworthy, and Innovative!
                          </h4>
                          <p className="card-text">
                            I love the capability to post videos. This is a very
                            special feature that other marketplaces lack. Trust
                            is paramount in the world of exchange marketplaces,
                            and this platform has earned mine. The transparent
                            fee structure, quick transaction processing, and
                            reliable performance makes it a trustworthy choice.
                            I've had a seamless experience, earning this
                            platform a well-deserved five stars.
                          </p>
                          <div className="custmoer_name">
                            Abigail Elizabeth Turner
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card mb-2" style={{ height: "400px" }}>
                        <div className="card-body">
                          <ul className="review-rating">
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                            <li>
                              <i className="fas fa-star" />
                            </li>
                          </ul>
                          <h4 className="card-title">Seamless Transactions</h4>
                          <p className="card-text">
                            I've been using this XCHANGE MARKETPLACE for various
                            transactions, and it has consistently delivered a
                            seamless experience. The quick processing times and
                            minimal downtime ensure that I can execute trades
                            efficiently. Five stars for reliability and
                            efficiency.
                          </p>
                          <div className="custmoer_name">
                            Victoria Lynn Sullivan
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*/.Second slide*/}
                {/*Third slide*/}
                {/* <div className="carousel-item">
                  <div className="row">
                    
                  </div>
                </div> */}
                {/*/.Third slide*/}
              </div>
              {/*/.Slides*/}
            </div>
            {/*/.Carousel Wrapper*/}
          </div>
        </div>
        {/* end recent_reviews_section */}
      </div>
      {/* end main */}

      {/* Optional JavaScript */}
      {/* jQuery first, then Popper.js, then Bootstrap JS */}
    </Layout>
  );
}

export default BuyerhubPage;
